@import url("./satoshi.css");
@import url("./eb-garamond.css");

/*Variables*/
:root {
  /*Colors:*/
  --primary-color: #fffdfb;
  --secondary-color: #000000;

  --primary-headline-color: #f34e21;
  --secondary-headline-color: #000000;

  --primary-container-color: #febfcb;
  --secondary-container-color: #f85f36;
  
  --button-color: #F5F5F5;

  --primary-button-hover-color: #94b647;
  --secondary-button-hover-color: #b2cee5;
  --tertiary-button-hover-color: #ffba1d; 
 
  --icon-color: #a09d9d;
  
  --icon-hover-color: #000000;   

  /*fonts */
  --primary-font-family: 'Satoshi', sans-serif;
  --secondary-font-family: 'EB-Garamond', sans-serif
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #94b647;
  transform-origin: 0%;
  z-index: 1010;
}
